import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import Lights from "./components/Lights.js";
import _, { delay } from "lodash";
import * as THREE from "three";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { EffectComposer, Bloom, Glitch } from "@react-three/postprocessing";
import CameraControls, {
  useCameraControls,
} from "./components/CameraControls.js";
import { create as createZustand } from "zustand";
import { useRace } from "./components/Race.js";
import Track from "./track/track.js";
import { Perf } from "r3f-perf";
import { RaceRunner, useRaceRunContext } from "./components/RaceRunner.js";
import { useControls } from "leva";
import Podium from "./components/Podium.js";
import { useState } from "react";
import { getv, nils } from "./utils/utils.js";
import { Environment, EnvironmentMap } from "@react-three/drei";
import TronBike from "./components/TronBike.js";
import { degToRad } from "three/src/math/MathUtils.js";
import { useAppContext } from "./App.js";
import { Effects } from "./wrappers/Effects.js";

export const useExperience = createZustand((set) => ({}));
const glitob = {
  stopped: [false, 0],
  running: [1, 0.5],
  replay: [2, 1],
  podium: [2, 0.5],
};

function Experience() {
  const r3fstate = useThree();
  const econ = {};

  const exp = useExperience();
  const camct = useCameraControls();
  const racect = useRace();

  const loaded = camct.loaded && racect.loaded;

  const horserefs = useRef([...Array(2)]);

  const appcon = useAppContext();
  const test = appcon.test;

  const dist = useMemo(() => {
    if (!racect.loaded || test) return 12 * 100;

    let cb = getv(racect, "race.cb");
    return cb * 100;
  }, [racect.loaded, test]);

  const rruncon = useRaceRunContext();
  const { runmode } = rruncon;

  const [applyglitch, set_applyglitch] = useState(false);
  useEffect(() => {
    if (!nils(glitob[runmode])) {
      let [str, dur] = glitob[runmode];
      if (!str) return;
      set_applyglitch(str);
      setTimeout(() => {
        set_applyglitch(false);
      }, dur * 1e3);
    }
  }, [runmode]);

  useEffect(() => {
    if (appcon.test !== true) return;
    if (camct.loaded == true) {
      let cam = camct.orbit.object;
      let orb = camct.orbit;
      setTimeout(() => {
        // cam.position.set(1200, 4.5, 9);
        // orb.target.set(1200, 0, 0);
        cam.position.set(10, 10, 10);
        orb.target.set(0, 0, 0);
      }, 1000);
      // console.log("init cam position", cam.position);
    }
  }, [camct.loaded]);

  return (
    <>
      {/* <Perf position="top-left" /> */}

      {/* <color args={["#000B14"]} attach={"background"} /> */}

      <>
        <Effects {...{ applyglitch }} />

        <CameraControls />
        <Lights />

        {test == true ? (
          <>
            {camct.loaded && (
              <>
                <Track dist={dist} />
                <TronBike
                  bike={{ name: "", hex_code: "FF0000", skin: "eth" }}
                />
                {/* <mesh rotation-x={degToRad(-90)}>
                  <planeGeometry args={[10, 10]} />
                </mesh>
                <mesh rotation-x={degToRad(-90)}>
                  <boxGeometry args={[1, 1, 1]} />
                  <meshBasicMaterial color="#00ff00" />
                </mesh> */}
              </>
            )}
          </>
        ) : (
          <>
            {camct.loaded && (
              <>
                {["running", "stopped", "replay"].includes(runmode) && (
                  <>
                    <RaceRunner />
                    <Track dist={dist} />
                  </>
                )}

                {["podium"].includes(runmode) && (
                  <>
                    <Podium />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Experience;
