import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";
import { useModelsStore } from "../ModelsStore.js";
import * as THREE from "three";

function ElectricTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const time = useMemo(() => Math.random() * 10, [bikeColor]);

  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime + time;
  });

  const material = useMemo(() => {
    return new ShaderMaterial({
      uniforms: {
        iTime: {
          type: "f",
          value: time * 2.5,
        },
        iResolution: {
          type: "v2",
          value: new Vector2(1, 1),
        },
      },

      transparent: true,
      side: 2,
      vertexShader: `
    varying vec2 vUv;
    
    void main() {
      vUv=uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
      fragmentShader: `//////////////////////

    uniform vec2 iResolution;
    uniform float iTime;
    varying vec2 vUv;

    // Original noise code from https://www.shadertoy.com/view/4sc3z2
    #define MOD3 vec3(.1031,.11369,.13787)
    
    vec3 hash33(vec3 p3)
    {
      p3 = fract(p3 * MOD3);
        p3 += dot(p3, p3.yxz+19.19);
        return -1.0 + 2.0 * fract(vec3((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y, (p3.y+p3.z)*p3.x));
    }
    
    float simplex_noise(vec3 p)
    {
        const float K1 = 0.333333333;
        const float K2 = 0.166666667;
        
        vec3 i = floor(p + (p.x + p.y + p.z) * K1);
        vec3 d0 = p - (i - (i.x + i.y + i.z) * K2);
            
        vec3 e = step(vec3(0.0), d0 - d0.yzx);
      vec3 i1 = e * (1.0 - e.zxy);
      vec3 i2 = 1.0 - e.zxy * (1.0 - e);
        
        vec3 d1 = d0 - (i1 - 1.0 * K2);
        vec3 d2 = d0 - (i2 - 2.0 * K2);
        vec3 d3 = d0 - (1.0 - 3.0 * K2);
        
        vec4 h = max(0.6 - vec4(dot(d0, d0), dot(d1, d1), dot(d2, d2), dot(d3, d3)), 0.0);
        vec4 n = h * h * h * h * vec4(dot(d0, hash33(i)), dot(d1, hash33(i + i1)), dot(d2, hash33(i + i2)), dot(d3, hash33(i + 1.0)));
        
        return dot(vec4(31.316), n);
    }
    
    void main()
    {    

        //vec2 ouv = fragCoord/iResolution.xy;
        vec2 uv = vec2(vUv.y, vUv.x)-0.5; 
        
        float m = 0.;
        for(int i=0;i<6;i++){    
            float f = floor(iTime*20.) + float(i)*.5;        
            float b = 
                simplex_noise(vec3(f, uv.y*1., 1.))*.15 + 
                simplex_noise(vec3(f, uv.y*5., 5.))*.1 +            
                simplex_noise(vec3(f, uv.y*15., 10.))*.02;
    
            float l = .000025+(uv.y+.5)*.00001;
            m += .0005/smoothstep(0., l*25e3, abs(b-uv.x));
        }
        
        m = min(m, 10.);
       
        vec3 ncol = vec3(1.) * m;   
        vec3 backCol = vec3(0.);
        vec3 col = mix(backCol, ncol, .2);
        
        gl_FragColor = vec4(${bikeColor.r},${bikeColor.g},${bikeColor.b},(1.-vUv.x)*(length(col)));
        gl_FragColor.xyz*=2.;
    }
    
`,
    });
  }, [bikeColor]);

  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}
export default ElectricTrail;
