import _ from "lodash";
import { create as createZustand } from "zustand";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { nils } from "../utils/utils.js";
import { useGLTF } from "@react-three/drei";
import { useRace } from "./Race.js";

const gltf_loaded = new GLTFLoader();

const ModelsStore = createContext();
export const useModelsStore = () => useContext(ModelsStore);

export const useModelStoreCt = createZustand((set) => ({
  loaded: false,
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));

export const base_remote = `https://dna-run-public.s3.us-east-2.amazonaws.com/fbike-3d-models`;
export const track_remote = `${base_remote}/main-track`;
export const trail_remote = `${base_remote}/trails`;

const models_list = [
  ["tronbike", `${base_remote}/tronbike_v2.glb`],
  ["biketrail", `${base_remote}/trails/main-trial.glb`],

  ["track_trackpiece", `${track_remote}/track_trackpiece_100m.glb`],
  ["startline_gates", `${base_remote}/building-city-track/startline_gates.glb`],
  ["podium_floorpiece", `${track_remote}/podium_gridpiece.glb`],

  ["templatetrail", `${trail_remote}/template-trail2.glb`],
  ["basetrail", `${trail_remote}/main-trail.glb`],
  ["fire_trail", `${trail_remote}/fire/model2.glb`],
  ["hurricane_trail", `${trail_remote}/hurricane/model2.glb`],
  ["swirl_trail", `${trail_remote}/swirl/model2.glb`],
];

const loadmodel = (name, path, progressCallback) => {
  return new Promise((resolve, reject) => {
    gltf_loaded.load(
      path,
      (gltf) => {
        const nodes = {};
        const materials = {};

        gltf.scene.traverse((child) => {
          if (child.isMesh && child.material) {
            materials[child.material.name] = child.material;
          }

          if (child.isObject3D) {
            nodes[child.name] = child;
          }
        });
        gltf.nodes = nodes;
        gltf.materials = materials;
        progressCallback(name, { done: 1 });
        resolve({ name, gltf });
      },
      (xhr) => {
        // console.log(`xhr ${name}`, xhr);
        progressCallback(name, { loaded: xhr.loaded, total: xhr.total });
      },
      (err) => {
        console.log("loadmodel error", name, err);
        reject({ name, err });
      },
    );
  });
};

export const ModelsStoreWrapper = (props) => {
  const racect = useRace();
  const modelstorect = useModelStoreCt();
  const [models, set_models] = useState({});
  const [loaded, set_loaded] = useState(false);

  const loadProgress = useRef({});
  const updateLoadProgress = (name, progress) => {
    let l = loadProgress.current;
    l[name] = {
      ...(l[name] || {}),
      ...progress,
    };
  };

  const [loadprogress_n, set_loadprogress_n] = useState(0);
  useEffect(() => {
    const fn = async () => {
      let l = loadProgress.current;
      let n = models_list.length;
      let done = _.chain(l)
        .filter((e) => e.done == 1)
        .size()
        .value();
      let p = done / n;
      // console.log({ n, done, p });
      set_loadprogress_n(p);
      modelstorect.update({ loadprogress_n: p });
      setTimeout(fn, 500);
    };
    fn();
  }, [loadProgress.current]);

  useEffect(() => {
    if (loaded == true) return;
    const fn = async () => {
      let ar = await Promise.all(
        models_list.map(([name, path]) => {
          return loadmodel(name, path, updateLoadProgress);
        }),
      );
      ar = _.chain(ar)
        .filter((e) => nils(e.err))
        .keyBy("name")
        .mapValues("gltf")
        .value();
      set_models(ar);
      set_loaded(true);
      modelstorect.update({ loaded: true });
    };
    if (racect.loaded == true) {
      fn();
    } else {
      console.log("modelstore waiting for race to load");
    }
  }, [racect.loaded]);

  const get_model = (id) => {
    if (!nils(models[id])) return models[id];
    else {
      return {};
    }
  };

  const models_store = {
    models,
    set_models,
    get_model,
    loaded,
    loadprogress_n,
  };
  return (
    <ModelsStore.Provider value={models_store}>
      {props.children}
    </ModelsStore.Provider>
  );
};
