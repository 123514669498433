import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { BufferAttribute, ShaderMaterial, Vector2, Vector4 } from "three";
import { trail_remote, useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function HurricaneTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("hurricane_trail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const material = useMemo(() => {
    const materials = new ShaderMaterial({
      uniforms: {
        iTime: {
          type: "f",
          value: time * 2.5,
        },
        iResolution: {
          type: "v2",
          value: new Vector2(100, 100),
        },
        lineColor: {
          type: "v4",
          value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
        },
      },
      transparent: true,
      side: 2,
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv=uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,
      fragmentShader: `//////////////////////
        // Fire Flame shader
        
        // procedural noise from IQ
        
            uniform vec2 iResolution;
            uniform float iTime;
              uniform vec4 lineColor;
              varying vec2 vUv;
            const float cloudscale = .1;
            const float speed = 1.;
            const float clouddark = 0.5;
            const float cloudlight = 0.3;
            const float cloudcover = 0.2;
            const float cloudalpha = 8.0;
            const float skytint = 0.5;
            const vec3 skycolour1 = vec3(0.2, 0.4, 0.6);
            const vec3 skycolour2 = vec3(0.4, 0.7, 1.0);
            
            const mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );
            
            vec2 hash( vec2 p ) {
              p = vec2(dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)));
              return -1.0 + 2.0*fract(sin(p)*43758.5453123);
            }
            
            float noise( in vec2 p ) {
                const float K1 = 0.366025404; // (sqrt(3)-1)/2;
                const float K2 = 0.211324865; // (3-sqrt(3))/6;
              vec2 i = floor(p + (p.x+p.y)*K1);	
                vec2 a = p - i + (i.x+i.y)*K2;
                vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0); //vec2 of = 0.5 + 0.5*vec2(sign(a.x-a.y), sign(a.y-a.x));
                vec2 b = a - o + K2;
              vec2 c = a - 1.0 + 2.0*K2;
                vec3 h = max(0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
              vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
                return dot(n, vec3(70.0));	
            }
            
            float fbm(vec2 n) {
              float total = 0.0, amplitude = 0.1;
              for (int i = 0; i < 7; i++) {
                total += noise(n) * amplitude;
                n = m * n;
                amplitude *= 0.4;
              }
              return total;
            }
            
            // -----------------------------------------------
            
            void main(  ) {
              vec2 p = vUv.xy / iResolution.xy;
              p*=10000.;
              p.y*=.05;
            vec2 uv = p*vec2(iResolution.x/iResolution.y,1.0);    
              float time = iTime * speed * 100000.;
              float q = fbm(uv * cloudscale * 5.);

              //ridged noise shape
            float r = 0.0;
            uv *= cloudscale;
              uv -= q - time;
              float weight = 0.8;
              r += abs(weight*noise( uv ));
                  uv = m*uv - time;
              weight *= 0.7;
              
              //noise shape
            float f = 0.0;
              
              f *= r + f;
              
              //noise colour
              float c = 0.0;
              time = iTime * speed * 2.0;
              uv = p*vec2(iResolution.x/iResolution.y,1.0);
              uv *= cloudscale*2.0;
              uv -= q - time;
              weight = 0.4;
              c += weight*noise( uv );
                  uv = m*uv + time;
              weight *= 0.6;
              
            
              
              
              vec3 skycolour = vec3(0.);
              vec3 cloudcolour = vec3(1.);
             
              f = cloudcover + cloudalpha*f*r;
              
              vec3 result = mix(skycolour, clamp(skytint * skycolour + cloudcolour, 0.0, 1.0), clamp(f + c, 0.0, 1.0));
              
              gl_FragColor = vec4( lineColor.xyz*5., max(0.0, (length(result)*(1.0-vUv.y))) );
              // gl_FragColor = vec4( length(vUv) );
            }`,
    });
    return materials;
  }, [bikeColor]);

  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime;
  });

  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}
export default HurricaneTrail;
