import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useCameraControls } from "./CameraControls.js";
import { useTrackContext } from "../track/track.js";
import { sx, trackpiece_per100m, metrics } from "../track/metrics";
import { useNowContext } from "../App.js";
import { useRace } from "./Race.js";
import { useRaceRunContext, useRaceRunnerControls } from "./RaceRunner.js";
import { Center, Text3D, useGLTF } from "@react-three/drei";
import useGLTF_cloned from "../loaders/useGLTF_cloned.js";
import TronBike from "./TronBike.js";
import _ from "lodash";
import { degToRad } from "three/src/math/MathUtils.js";
import { jstr, nils, postxt } from "../utils/utils.js";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { useModelsStore } from "./ModelsStore.js";

const PodiumContext = createContext();
const usePodiumContext = () => useContext(PodiumContext);

const payouts_ob = {
  "1v1": [[1, 1]],
  wta: [[1, 1]],
  top2: [
    [1, 0.7],
    [2, 0.3],
  ],
  top3: [
    [1, 0.65],
    [2, 0.25],
    [3, 0.1],
  ],
  top4: [
    [1, 0.25],
    [2, 0.25],
    [3, 0.25],
    [4, 0.25],
  ],
  dblup6: [
    [1, 0.33],
    [2, 0.33],
    [3, 0.33],
  ],
  dblup12: [
    [1, 0.166],
    [2, 0.166],
    [3, 0.166],
    [4, 0.166],
    [5, 0.166],
    [6, 0.166],
  ],
  pity6: [
    [2, 0.6],
    [3, 0.6],
    [4, 0.6],
  ],
  pity12: [
    [4, 0.6],
    [3, 0.6],
    [5, 0.6],
  ],
};

const podmetrics = {
  bikescale: 10,
  memmap: {
    1: {
      posx: 0.3,
      posz: 0,
      roty: 20,
    },
    2: {
      posx: -0.1,
      posz: 1,
      roty: 45,
    },
    3: {
      posx: -0.2,
      posz: -1.3,
      roty: 20,
    },
  },
};

const FloorPiece = ({ x, z }) => {
  const pcon = usePodiumContext();
  const { models } = pcon;
  const trackpiece = useGLTF_cloned(models.trackpiece);
  return (
    <primitive
      position-x={x}
      position-z={z}
      position-y={0}
      object={trackpiece.scene}
    />
  );
};
const BGScene = () => {
  const pcon = usePodiumContext();
  const { models } = pcon;

  const trackgrid = [10, 10];

  return (
    <>
      <group position-y={-0.8} scale={metrics.bridgescale}>
        {[...Array(trackgrid[0])].map((a, i) =>
          [...Array(trackgrid[1])].map((b, j) => {
            let x = (i - (trackgrid[0] - 1) / 2) * 80;
            let z = (j - (trackgrid[1] - 1) / 2) * 184.5;
            let o = { x, z, key: `${i}-${j}` };
            return <FloorPiece {...o} />;
          }),
        )}
      </group>
    </>
  );
};

const PodiumMember = ({ podidx, pos, hid, h }) => {
  const mem = podmetrics.memmap[podidx];
  const bikesl = podmetrics.bikescale;
  const neon = `#${h.hex_code}`;
  const neon_material = useRef(null);

  const href = useRef();
  if (nils(h) || nils(mem)) return <></>;
  return (
    <group position-x={mem.posx * bikesl} position-z={mem.posz * bikesl}>
      <meshBasicMaterial
        ref={neon_material}
        color={new THREE.Color(neon)}
        emissive={new THREE.Color(neon)}
        emissiveIntensity={3}
      />

      <TronBike
        modelcentered={true}
        ref={href}
        scale={bikesl}
        position-y={0}
        rotation-y={degToRad(mem.roty)}
        bike={h}
      />

      <Center position-y={10} rotation-y={degToRad(90 - mem.roty * 0.7)}>
        <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={1}
          height={0.1}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
        >
          {h.name?.split(" ").join("\n")}
          <meshStandardMaterial
            color={new THREE.Color("#ffffff")}
            emissive={new THREE.Color(neon)}
            emissiveIntensity={2}
          />
        </Text3D>
        <Text3D
          font="./fonts/Audiowide_Regular.json"
          size={2}
          height={0.2}
          curveSegments={12}
          bevelEnabled
          bevelThickness={0.02}
          bevelSize={0.02}
          bevelSegments={5}
          position-y={3}
        >
          {postxt(pos)}
          <meshStandardMaterial
            color={new THREE.Color("#ffffff")}
            emissive={new THREE.Color("#ffffff")}
            emissiveIntensity={10}
          />
        </Text3D>
      </Center>
    </group>
  );
};

function Podium() {
  const camct = useCameraControls();
  const { now } = useNowContext();

  const racect = useRace();
  const raceruncon = useRaceRunContext();

  const { race, hsob, racerunhs, rtstatus } = racect;
  const { runmode } = raceruncon;

  const models_store = useModelsStore();
  const trackpiece_gltf = models_store.get_model("podium_floorpiece");

  const models = {
    trackpiece: trackpiece_gltf,
  };

  const podium_map = useMemo(() => {
    if (nils(race)) return [];
    let poshids = _.chain(race.hs).keyBy("pos").mapValues("hid").value();
    let pmap = _.chain(race.prize_map)
      .keys()
      .map((e) => parseInt(e))
      .compact()
      .slice(0, 3)
      .value();
    if (_.isEmpty(pmap)) pmap = [1];
    return pmap.map((pos, i) => {
      let hid = poshids[pos];
      let h = hsob[hid];
      return { pos, hid, h, podidx: i + 1 };
    });
  }, [jstr(race), jstr(hsob)]);

  // const podrot_elap = useRef(degToRad(-20) * 10);
  // const podrot_speed = useRef(+1);
  // const smoothcam = useRef(null);

  /*
  useFrame((rf3s, delta) => {
    if (camct.loaded == false) return;

    podrot_elap.current += delta * podrot_speed.current;

    const orbit = camct?.orbit;
    const camera = camct?.orbit?.object;
    const tarobj = camct?.tarobj;

    let elap = podrot_elap.current;
    let rad = elap * 0.1;

    if (rad > degToRad(90)) podrot_speed.current = 5;
    else podrot_speed.current = podrot_speed.current = 2;

    if (smoothcam.current == null) {
      smoothcam.current = new THREE.Vector3(108.33, 29.45, 24.37);
    }

    let campos = new THREE.Vector3().copy(camera.position);
    campos.x = Math.cos(rad) * 120;
    campos.z = Math.sin(rad) * 120;
    smoothcam.current.lerp(campos, 0.1);
    camera.position.copy(smoothcam.current);

    orbit.target.set(0, 0, 0);
  });
  */

  const pcon = {
    models,
    podium_map,
  };

  return (
    <PodiumContext.Provider value={pcon}>
      <directionalLight position={[0, 20, 60]} intensity={0.4} />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[0, 0, 50]}
        intensity={0.3}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[5, 0, 50]}
        intensity={0.15}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[0, 0, -50]}
        intensity={0.3}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[-5, 0, 4]}
        intensity={0.15}
        color="#ffffff"
      />

      <BGScene />

      <group position={[0, 0, 0]}>
        {podium_map.map((p, i) => {
          return <PodiumMember key={i} {...p} />;
        })}
      </group>
    </PodiumContext.Provider>
  );
}
export default Podium;
