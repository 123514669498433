import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function ColdPlasmTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime * 2.5;
  });

  //   const material =
  const material = useMemo(() => {
    const materials = new ShaderMaterial({
      uniforms: {
        iTime: {
          type: "f",
          value: time,
        },
        iResolution: {
          type: "v2",
          value: new Vector2(1, 1),
        },
        lineColor: {
          type: "v4",
          value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
        },
      },

      transparent: true,
      side: 2,
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv=uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,
      fragmentShader: `//////////////////////
    
        uniform vec2 iResolution;
        uniform float iTime;
        uniform vec4 lineColor;
        varying vec2 vUv;
    
        
        void main()
        {
          vec4 fragColor = vec4(0.);
            fragColor.rgb = vec3(0.0);
          vec2 uv = 3.*vUv-1.2;
            float time = (iTime*2.5+sin(iTime))+(0.0/iResolution.x*10.0);
            for( int i=0; i<512; i++ )
            {
                float r1 = fract(sin(float(i)*64.19)*420.82);
                float r2 = fract(sin(float(i)*38.57)*560.21);
                float val = 1.8*pow((1.0-length(uv-vec2(uv.x+(cos((uv.x)*r1*r2*100.0
                  -atan(uv.y-sin(time*0.3), uv.x+0.2)*42.0*r1+time*r2)*cos(uv.x+r1-time*0.5)*0.5*r1)
                    , sin(uv.x*r2*2.0+time*r2)*(sin(uv.x+r2-time*0.5)*0.5*r2)+0.5)))
                    , 8.0+r2*420.0);
              fragColor.rgb += vec3(r1, r2, r2+.01)*val;
            }
            vec3 col=mix( fragColor.xyz,lineColor.xyz, .0);
            gl_FragColor = vec4(max(col, 0.), max(((1.-vUv.x)/1.2)*length(col), 0.01));
            
        }
    `,
    });
    return materials;
  }, []);
  return (
    <group {...props}>
      <mesh
        ref={trailRef}
        scale={[1, 4, 1]}
        {...nodes.Plane}
        material={material}
      />
    </group>
  );
}

export default ColdPlasmTrail;
