import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";
import { useModelsStore } from "../ModelsStore.js";

function RainbowTrail2({ ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  useFrame(({ clock }) => {
    setTime(clock.elapsedTime);
  });

  const material = new ShaderMaterial({
    uniforms: {
      iTime: {
        type: "f",
        value: time * 20,
      },
      iResolution: {
        type: "v2",
        value: new Vector2(1, 1),
      },
    },

    transparent: true,
    side: 2,
    vertexShader: `
    varying vec2 vUv;
    
    void main() {
      vUv=uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
    fragmentShader: `//////////////////////

    uniform vec2 iResolution;
    uniform float iTime;
    varying vec2 vUv;

    
    #define S(a, b, c) smoothstep(a, b, c)
    #define sat(a) clamp(a, 0.0, 1.0)

    #define pi 3.14159265359
    #define pi2 (pi * 2.0)
    #define halfPi (pi * 0.5)
    float N2(vec2 p)
    {	// Dave Hoskins - https://www.shadertoy.com/view/4djSRW
        p = mod(p, vec2(1456.2346));
      vec3 p3  = fract(vec3(p.xyx) * vec3(443.897, 441.423, 437.195));
        p3 += dot(p3, p3.yzx + 19.19);
        return fract((p3.x + p3.y) * p3.z);
    }


    float CosineInterpolate(float y1, float y2, float t)
    {
      float mu = (1.0 -cos(t * pi)) * 0.5;
      return (y1 * (1.0 - mu) + y2 * mu);
    }

    // A 2d Noise (Cosine interpolation is more plasing for this effect)
    float Noise2(vec2 uv)
    {
        vec2 corner = floor(uv);
      float c00 = N2(corner + vec2(0.0, 0.0));
      float c01 = N2(corner + vec2(0.0, 1.0));
      float c11 = N2(corner + vec2(1.0, 1.0));
      float c10 = N2(corner + vec2(1.0, 0.0));
        
        vec2 diff = fract(uv);
        
        return CosineInterpolate(CosineInterpolate(c00, c10, diff.x), CosineInterpolate(c01, c11, diff.x), diff.y);
    }

    float LineNoise(float x, float t)
    {
        float n = Noise2(vec2(x * 0.6, t * 0.2));
        //n += Noise2(vec2(x * 0.8, t * 0.2 + 34.8)) * 0.5;
        //n += Noise2(vec2(x * 1.2, t * 0.3 + 56.8)) * 0.25;
        
        return n - (1.0) * 0.5;
    }


    float line(vec2 uv, float t, float scroll)
    {
        
        float ax = abs(uv.x);
        uv.y *= 0.5 + ax * ax * 0.3; 
        
            
        uv.x += iTime * scroll;
        
        float n1 = LineNoise(uv.x, t);
        
        
        float n2 = LineNoise(uv.x + 0.5, t + 10.0) * 2.0;
        
        float ay = abs(uv.y - n1);
        float lum = S(0.02, 0.00, ay) * 1.5;
        lum += S(1.5, 0.00, ay) * 0.1;
        
      
        float r = (uv.y - n1) / (n2 - n1);
        float h = sat(1.0 - r);
        if (r > 0.0) lum = max(lum, h * h * 0.7);
        
        return lum;
    }

    void main()
    {
        // Normalized pixel coordinates (from 0 to 1)
        vec2 uv = vUv*-4.+2.1;

        
        float xWave = vUv.x/2.;
        
        float lum = line(uv * vec2(2.0, 1.0), iTime * 0.3, 0.1) * 0.6;
        lum += line(uv * vec2(1.5, 0.9), iTime * 0.5 + 45.0, 0.15) * 0.5;
        lum += line(uv * vec2(1.3, 1.2), iTime * 0.4 + 67.3, 0.2) * 0.3;
        lum += line(uv * vec2(1.5, 1.15), iTime * 0.77 + 1235.45, 0.23) * 0.43;
        lum += line(uv * vec2(1.5, 1.15), iTime * 0.77 + 456.45, 0.3) * 0.25;
      
        
        float ax = abs(uv.x);
        
        lum += ax * ax * 0.05;
      

        vec3 col;
        
        float x = uv.x * 1.2 + iTime * 0.2;
        
        vec3 hue = (sin(vec3(x, x + pi2 * 0.33, x + pi2 * 0.66)) + vec3(1.0)) * 0.7;
        

        // overlay mix
        float thres = 2.;
        if (lum < thres)
            col = hue * lum / thres;
        else
            col = vec3(1.0) - (vec3(1.0 - (lum - thres)) * (vec3(1.0) - hue));
        
        
        // Output to screen
        gl_FragColor = vec4(2.0*col,max((1.-vUv.x)*2.*length(col)-0.2, 0.0));//
    }
`,
  });
  return (
    <group {...props}>
      <mesh ref={trailRef} {...nodes.Plane} material={material} />
    </group>
  );
}

export default RainbowTrail2;
