import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";
import { useModelsStore } from "../ModelsStore.js";

function ShardTrail({ bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("templatetrail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;
  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const originalPositions = useMemo(() => {
    const positions = [...nodes.Plane.geometry.attributes.position.array];
    return new Float32Array(positions);
  }, []);

  const originalUvs = useMemo(() => {
    const uvs = [...nodes.Plane.geometry.attributes.uv.array];
    return new Float32Array(uvs);
  }, []);

  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime * 2.5;
  });

  //   const material =
  const material = useMemo(() => {
    const materials = new ShaderMaterial({
      uniforms: {
        iTime: {
          type: "f",
          value: time,
        },
        iResolution: {
          type: "v2",
          value: new Vector2(1, 1),
        },
        lineColor: {
          type: "v4",
          value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
        },
      },

      transparent: true,
      side: 2,
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv=uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,
      fragmentShader: `//////////////////////
    
        uniform vec2 iResolution;
        uniform float iTime;
        uniform vec4 lineColor;
        varying vec2 vUv;
    
        
       
    #define PI 3.141592653589
    #define PI2 6.28318530718
    
    vec2 hash( vec2 p ){
        p = vec2( dot(p,vec2(127.1,311.7)),dot(p,vec2(269.5,183.3)));
        return fract(sin(p)*43758.5453);
    }
    
    float rand(float n){
        return fract(sin(n) * 43758.5453123);
    }
    
    float rand(vec2 n) { 
        return fract(cos(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
    }
    
    vec4 mapStar(vec2 position, float lightSpeed, float angleOffset) {
        
        vec4 color = vec4(vec3(0.), 1.);
        
        float angle = atan(position.y, position.x) + angleOffset;
        
        float angleNumber = 12.;
        
        float random = rand(floor(angle * angleNumber) * 100.);
        
        float distance = random;
        distance += iTime * 2.;
        distance = fract(distance);
        
        float fragDistance = length(position);
        
        float size = smoothstep(-.1, 2.3, lightSpeed);
        
        
        float bounded = step(distance, fragDistance) * step(fragDistance, distance + size);
        bounded += step(fragDistance, fract(distance + size)) * step(fract(distance + size), distance);
        
        color.rgb = mix(vec3(0.), vec3(1.), bounded);
        
        color.r *= 1.;
        color.g *= .7 + random * .3;
        color.b *= 4.;
        
        // hard edges
        float angleCenter = abs(fract(angle * angleNumber) * 2. - 1.);
        color.a = step(angleCenter, smoothstep(-.2, 1., lightSpeed));
        
        // smooth gradient
        color.a *= 1. - angleCenter;
        
        color.a *= smoothstep(0., 1., fragDistance);
        color.a *= cos(random * iTime) * .5 + .5;
        
        return color;
    }
    float sdTriangle( in vec2 p, in vec2 p0, in vec2 p1, in vec2 p2 )
{
	vec2 e0 = p1 - p0;
	vec2 e1 = p2 - p1;
	vec2 e2 = p0 - p2;

	vec2 v0 = p - p0;
	vec2 v1 = p - p1;
	vec2 v2 = p - p2;

	vec2 pq0 = v0 - e0*clamp( dot(v0,e0)/dot(e0,e0), 0.0, 1.0 );
	vec2 pq1 = v1 - e1*clamp( dot(v1,e1)/dot(e1,e1), 0.0, 1.0 );
	vec2 pq2 = v2 - e2*clamp( dot(v2,e2)/dot(e2,e2), 0.0, 1.0 );
    
    float s = e0.x*e2.y - e0.y*e2.x;
    vec2 d = min( min( vec2( dot( pq0, pq0 ), s*(v0.x*e0.y-v0.y*e0.x) ),
                       vec2( dot( pq1, pq1 ), s*(v1.x*e1.y-v1.y*e1.x) )),
                       vec2( dot( pq2, pq2 ), s*(v2.x*e2.y-v2.y*e2.x) ));

	return -sqrt(d.x)*sign(d.y);
}

    void main( )
    {
        
        float lightSpeed = cos(iTime) * .5 + .5;
        
        lightSpeed = 1.;
        
        //lightSpeed = 1.;
        
        vec2 uv = vUv;
        
        vec2 position = vec2(uv.x,.55-uv.y);
        
        position.y *= iResolution.y / iResolution.x;
        
        vec4 color = vec4(0.05);
        color.a = 1.;
        
        for(float i = 0.; i < 3.; i++) {
            vec4 starColor = mapStar(position, lightSpeed, i * 20.);
            color.rgb += starColor.rgb * starColor.a;
            color.a += starColor.a * .2;
        }
        
        // color.a *= .5;
        
        color += smoothstep(.9, 1., lightSpeed);
        
        // gl_FragColor = length(color);
        float my_a=length(color)/5.0;
        if(my_a <.58 ){my_a=0.0;}

        vec2 p = uv;
        p-=.6;
        // animate
        vec2 v1 = vec2(1.4,1.0)*cos( 0. + vec2(-0.4,1.400) + 0.0 );
        vec2 v2 = vec2(1.4,1.0)*cos( 0. + vec2(-0.4,1.800) + 0.0 );
        vec2 v3 = vec2(1.4,1.0)*cos( 0. + vec2(-2.,1.60) + .0 );

        // distance
        float d = sdTriangle( p, v1, v2, v3 );

        gl_FragColor = vec4((color.xyz*lineColor.xyz)*5., max(0.0,my_a*(1.-vUv.x)*10.*(.1-d)));
        
        
    }
    `,
    });
    return materials;
  }, [bikeColor]);
  return (
    <group position={[-0.2, 0, 0]}>
      <group {...props}>
        <mesh
          ref={trailRef}
          scale={[1, 4, 1]}
          {...nodes.Plane}
          material={material}
        />
      </group>
    </group>
  );
}

export default ShardTrail;
