import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useMemo, useRef, useState } from "react";
import { Vector2 } from "three";
import { Vector4 } from "three";
import { ShaderMaterial } from "three";
import { TextureLoader } from "three";
import { trail_remote, useModelsStore } from "../ModelsStore.js";
import useGLTF_cloned from "../../loaders/useGLTF_cloned.js";

function FireTrail({ setMaterial, bikeColor, ...props }) {
  const modelstore = useModelsStore();
  const m0 = modelstore.get_model("fire_trail");
  const model = useGLTF_cloned(m0);
  const { nodes, materials } = model;

  const trailRef = useRef();
  const [time, setTime] = useState(0);
  // Create an array to store the original vertex positions
  const material = useMemo(() => {
    const materials = new ShaderMaterial({
      uniforms: {
        iTime: {
          type: "f",
          value: time * 402.5,
        },
        iResolution: {
          type: "v2",
          value: new Vector2(1, 1),
        },
        lineColor: {
          type: "v4",
          value: new Vector4(bikeColor.r, bikeColor.g, bikeColor.b, 1),
        },
      },
      transparent: true,
      side: 2,
      vertexShader: `
        varying vec2 vUv;
        
        void main() {
          vUv=uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }`,
      fragmentShader: `//////////////////////
        // Fire Flame shader
        
        // procedural noise from IQ
        
            uniform vec2 iResolution;
            uniform vec4 lineColor;
            uniform float iTime;
        vec2 hash( vec2 p )
        {
          p = vec2( dot(p,vec2(127.1,311.7)),
               dot(p,vec2(269.5,183.3)) );
          return -1.0 + 2.0*fract(sin(p)*43758.5453123);
        }
        
        float noise( in vec2 p )
        {
          const float K1 = 0.366025404; // (sqrt(3)-1)/2;
          const float K2 = 0.211324865; // (3-sqrt(3))/6;
          
          vec2 i = floor( p + (p.x+p.y)*K1 );
          
          vec2 a = p - i + (i.x+i.y)*K2;
          vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0);
          vec2 b = a - o + K2;
          vec2 c = a - 1.0 + 2.0*K2;
          
          vec3 h = max( 0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
          
          vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
          
          return dot( n, vec3(70.0) );
        }
        
        float fbm(vec2 uv)
        {
          float f;
          mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );
          f  = 0.5000*noise( uv ); uv = m*uv;
          f += 0.2500*noise( uv ); uv = m*uv;
          f += 0.1250*noise( uv ); uv = m*uv;
          f += 0.0625*noise( uv ); uv = m*uv;
          f = 0.5 + 0.5*f;
          return f;
        }
        
        // no defines, standard redish flames
        //#define BLUE_FLAME
        //#define GREEN_FLAME
        varying vec2 vUv;
    
        void main()
        {
          vec2 uv = .4*vUv.yx / iResolution.yx;
          vec2 q = uv;
          //q.x *= 5.;
          q.y *= 4.;
          float strength = floor(q.x+2.);
          float T3 = max(3.,1.25*strength)*iTime*2.;
          q.x = mod(q.x,1.)-0.3;
          q.y -= 0.25;
          float n = fbm(strength*q - vec2(0,T3));
          float c = 1. - 16. * pow( max( 0., length(q*vec2(1.8+q.y*1.5,.75) ) - n * max( 0., q.y+.25 ) ),1.2 );
        //	float c1 = n * c * (1.5-pow(1.25*uv.y,4.));
          float c1 = n * c * (1.5-pow(2.50*uv.y,4.));
          c1=clamp(c1,0.,1.);
        
          vec3 col = vec3(1.5*c1, 1.5*c1*c1*c1, c1*c1*c1*c1*c1*c1);
          
        #ifdef BLUE_FLAME
          col = col.zyx;
        #endif
        #ifdef GREEN_FLAME
          col = 0.85*col.yxz;
        #endif
          
          float a = c * (1.-pow(uv.y,3.))*(1.-uv.y);
          vec3 ccol = mix(vec3(0.),col,a);
          // gl_FragColor  = vec4(mix(ccol, lineColor.xyz, .5), length(ccol));
          gl_FragColor  = vec4(ccol, length(ccol));
        }`,
    });
    return materials;
  }, []);

  useFrame(({ clock }) => {
    material.uniforms.iTime.value = clock.elapsedTime * 2.5;
  });

  return (
    <group position={[0, 0.1, 0]}>
      <group {...props}>
        <mesh ref={trailRef} {...nodes.Plane} material={material} />
      </group>
    </group>
  );
}

export default FireTrail;
